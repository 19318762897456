import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import { Colors } from '../config/default';
import { get_tabs } from '../redux/actions/action';
import { userRoles, documentStatusText } from '../constants/appConstants';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <ThemeProvider
      theme={theme}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </ThemeProvider>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function TabPanel({
  tabs,
  peddingBottom,
  activeTab,
  setActiveTab,
  tab,
  handleClear,
  filteredData,
  recordExist,
  path,
  orientation,
  setCheckedIds,
  handleClearAttachmentSearch,
  handleClearErpSearch,
  getAppliedFilters
}) {
  const { ADMIN, VENDOR } = userRoles;
  const { WAITING, POPULATED, REJECT, EXPORT } = documentStatusText;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const queue = useSelector((state) => state?.queue?.queue[0]);
  const getTabCount = (tabName) => {
    if (!queue) return 0;
    switch (tabName) {
      case 'To Review':
        return queue?.counts?.Populated || 0;
      case 'Rejected':
        return queue?.counts?.Reject || 0;
      case 'On Hold':
        return queue?.counts?.onHold || queue?.counts?.['on Hold'];
      case 'Exported':
        return queue?.documents?.filter((item) => item?.exportCsvStatus === EXPORT)?.length || 0;
      case 'Classify/Split':
        return queue?.counts?.Batch || 0;
      default:
        return 0;
    }
  };

  const handleChange = (e, tabName) => {
    if (userRole !== 'Admin') {
      if (
        (tab === 'parent' || tab === 'child') &&
        (!isEmpty(filteredData) ||
          recordExist ||
          (getAppliedFilters && getAppliedFilters()?.length > 0))
      ) {
        handleClear();
      }
    }
    if (tab === 'parent' && handleClearAttachmentSearch) {
      handleClearAttachmentSearch();
    }
    if (handleClearErpSearch) {
      handleClearErpSearch();
    }
    localStorage.setItem('chequeNo', '');
    localStorage.setItem('searchedKey', '');
    localStorage.setItem('instrumentId', '');
    setActiveTab(tabName);
    if (tab !== 'parent') {
      setCheckedIds([]);
    }
    if (path === 'profile' || path === 'Details' || path === 'users') {
      dispatch(
        get_tabs({
          activeTab: tabName,
          documentChildActiveTab: globalActiveTab?.documentChildActiveTab,
          IntegrationChildActiveTab: globalActiveTab?.IntegrationChildActiveTab
        })
      );
      userRole === ADMIN
        ? navigate('/admin')
        : userRole === VENDOR
        ? navigate('/vendor')
        : navigate('/home');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: {
            xs: 'fit-content',
            md: tab === 'child' ? '500px' : 'fit-content',
            xl: 'fit-content'
          },
          color: Colors.WHITE,
          height: { xs: 'auto', lg: '4.5rem' },
          marginBottom: { md: '0rem', lg: '0rem' },
          overflowX: { xs: tab === 'parent' || tab === 'child' ? 'scroll' : '', md: 'hidden' },
          padding: 0
        }}>
        <Box>
          <Tabs
            orientation={orientation ? 'vertical' : 'horizontal'}
            onChange={handleChange}
            value={activeTab}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              sx: {
                backgroundColor: 'secondary.light',
                height: '5px',
                width: { xs: '0.25rem', sm: '4.5rem', lg: '8.5rem' }
              }
            }}>
            {tabs?.map((childTab, index) => (
              <Tab
                icon={childTab.icon}
                iconPosition="start"
                label={
                  tab === 'child' &&
                  getTabCount(childTab?.name) !== 0 &&
                  getTabCount(childTab?.name) !== '0' ? (
                    <span>
                      {childTab?.name} <strong>({getTabCount(childTab?.name)})</strong>
                    </span>
                  ) : (
                    childTab?.name
                  )
                }
                value={childTab?.name}
                key={childTab?.name}
                sx={{
                  fontSize: tab === 'child' ? '.8rem' : '.9rem',
                  fontFamily: 'Roboto',
                  textTransform: 'none',
                  paddingBottom: peddingBottom,
                  fontWeight: 400,
                  display: 'flex',
                  justifyContent: 'start',
                  color: Colors.WHITE,
                  '&.Mui-selected': {
                    color: Colors.TOURQUISE
                  }
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
