import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxGroup({ data, setSelectedOptions, selectedOptions, toShow }) {
  const handleChange = (event) => {
    const { value, checked } = event.target;
    toShow === 'email'
      ? setSelectedOptions(checked)
      : setSelectedOptions((prev) =>
          checked ? [...prev, value] : prev?.filter((item) => item !== value)
        );
  };
  return (
    <FormGroup>
      {data?.map((item) => {
        return (
          <FormControlLabel
            key={item?.value}
            control={
              <Checkbox
                size="small"
                value={item?.value}
                checked={
                  toShow === 'email' ? selectedOptions : selectedOptions?.includes(item?.value)
                }
                onChange={handleChange}
              />
            }
            label={item?.label}
            sx={{
              '& .MuiTypography-root': {
                fontSize: '14px',
                margin: '.2rem'
              }
            }}
          />
        );
      })}
    </FormGroup>
  );
}
