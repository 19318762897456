import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import { Close, ErrorOutline } from '@mui/icons-material';
import { Grid, CircularProgress, Typography, Modal, Box } from '@mui/material';

import { getSyncedData } from '../services/services';
import { QueuePopup } from '../constants/appConstants';
import { Colors } from '../config/default';
import PoInvoiceList from '../pages/poInvoiceList';
import ColumnView from './columnView';
import SearchBar from './searchBar';

function SyncedDataList({ heading, type, toggleDrawer, anchor, searchTiltle }) {
  const { NO_RECORD } = QueuePopup;
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDataList, setFilteredDataList] = useState([]);
  const [dataList, setDataList] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const erpType = userDetails?.user?.erpType;
  const getData = async () => {
    setLoading(true);
    const result = await getSyncedData(type);
    if (result?.status === 200) {
      setDataList(result?.data?.payload?.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (type) {
      getData();
    }
  }, [type]);
  useEffect(() => {
    const filteredData = dataList?.filter((item) => {
      const newItem =
        erpType === 'Foundation' && type === 'jobIds'
          ? item?.job
          : (erpType === 'Foundation' || erpType === 'RentalMan') && type === 'purchaseOrders'
          ? item?.po
          : item;
      return newItem?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });
    setFilteredDataList(filteredData);
  }, [searchQuery, dataList]);

  const handleOpenPopup = (value) => {
    setSelectedValue(value);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setSelectedValue(null);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: Colors.Canvas_BG,
    borderRadius: '20px',
    p: 3,
    zIndex: '100000'
  };
  const isFoundation =
    type === 'purchaseOrders' && erpType === 'Foundation' && activeIntegration !== '';
  const isRentalMan = type === 'purchaseOrders' && erpType === 'RentalMan';
  return (
    <Grid
      sx={{
        alignItems: 'center',
        height: '100vh',
        backgroundColor: Colors.NAV_BLACK
      }}>
      <>
        <Grid
          container
          sx={{ padding: '0em 2em', mt: '.7em', mb: '.7em', justifyContent: 'flex-end' }}>
          <Close
            onClick={toggleDrawer(anchor, false)}
            sx={{ cursor: 'pointer', fontSize: '1.5em', color: Colors.LIGHTGREY }}
          />
        </Grid>
        <Grid
          container
          sx={{
            height: '90%',
            width: { xs: '20em', sm: '28em', xl: '42em' },
            padding: '2em 0.8em',
            borderRadius: '1em',
            backgroundColor: Colors.BG_BLACK,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            m: '0em 2em'
          }}>
          {type !== 'jobIds' && (
            <Grid item>
              <Typography sx={{ color: Colors.TOURQUISE, fontSize: '1em', ml: '1.1em' }}>
                {heading}{' '}
                {isFoundation && (
                  <span style={{ color: Colors.WHITE, fontSize: '0.85em' }}>
                    (Click on Po# to view details)
                  </span>
                )}
              </Typography>
            </Grid>
          )}
          {loading ? (
            <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '75vh' }}>
              <CircularProgress size={25} sx={{ color: Colors.DARKBLUE }} />
            </Grid>
          ) : (
            <>
              <Grid
                item
                sx={{
                  marginTop: '.5rem',
                  height: '75vh',
                  overflow: 'auto',
                  width: '98%'
                }}>
                {isEmpty(dataList) ? (
                  <Grid
                    container
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: Colors.TEXTGREY
                    }}>
                    <ErrorOutline
                      sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.1em' }}
                    />
                    <Typography>{NO_RECORD}</Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      item
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        justifyContent: 'flex-end',
                        position: 'sticky',
                        top: 0,
                        zIndex: 10,
                        backgroundColor: '#141414'
                      }}>
                      <SearchBar
                        width="100%"
                        setSearchProducts={setSearchQuery}
                        text={`Search by ${searchTiltle}...`}
                        searchProducts={searchQuery}
                        handleClear={(e) => {
                          setSearchQuery('');
                        }}
                        tooltipText={`Search by ${searchTiltle}...`}
                        dontShowIcon={true}
                        filteredData={filteredDataList}
                      />
                    </Grid>
                    {type === 'jobIds' && activeIntegration === 'Foundation' ? (
                      <ColumnView
                        option1="Job Id"
                        option2="Manager Name"
                        option3="Manager Email"
                        dataList={filteredDataList}
                      />
                    ) : type === 'purchaseOrders' &&
                      (erpType === 'Foundation' || erpType === 'RentalMan') ? (
                      <ColumnView
                        option1="PO#"
                        option2="Vendor"
                        option3="Total"
                        dataList={filteredDataList}
                        isFoundation={erpType === 'RentalMan' ? isRentalMan : isFoundation}
                        handleOpenPopup={handleOpenPopup}
                      />
                    ) : (
                      filteredDataList?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}>
                          <div
                            style={{
                              minWidth: '3rem',
                              textAlign: 'center',
                              color: Colors.TOURQUISE,
                              marginRight: '.5rem',
                              padding: '0.2rem 0',
                              whiteSpace: 'nowrap'
                            }}>
                            {`${index + 1}.`}
                          </div>
                          <span>{item}</span>
                        </div>
                      ))
                    )}
                  </>
                )}
              </Grid>
              <div>
                <Modal
                  open={openPopup}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description">
                  <Box
                    sx={{
                      ...style,
                      width: {
                        xs: '90vw',
                        md: '90vw',
                        lg: '75vw',
                        xl: '60vw'
                      }
                    }}>
                    <Close
                      sx={{
                        color: Colors.TEXTGREY,
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        position: 'absolute'
                      }}
                      onClick={handleClosePopup}
                    />
                    <PoInvoiceList poValue={selectedValue} />
                  </Box>
                </Modal>
              </div>
            </>
          )}
        </Grid>
      </>
    </Grid>
  );
}

export default SyncedDataList;
