import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Grid, Typography } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';

import SearchBar from './searchBar';
import { Colors } from '../config/default';
import { QueuePopup } from '../constants/appConstants';

function ListView({ dataList, searchTiltle }) {
  const { NO_RECORD } = QueuePopup;
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDataList, setFilteredDataList] = useState([]);

  useEffect(() => {
    const filteredData = dataList?.filter((item) => {
      const newItem = item;
      return newItem?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });
    setFilteredDataList(filteredData);
  }, [searchQuery, dataList]);
  return (
    <Grid
      item
      sx={{
        marginTop: '.5rem',
        overflowY: 'auto',
        height: '47vh'
      }}>
      {isEmpty(dataList) ? (
        <Grid
          container
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: Colors.TEXTGREY
          }}>
          <ErrorOutline sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.1em' }} />
          <Typography>{NO_RECORD}</Typography>
        </Grid>
      ) : (
        <>
          <Grid
            container
            item
            xs={12}
            sx={{ marginBottom: '1rem', marginTop: '.5rem', justifyContent: 'flex-end' }}>
            <SearchBar
              xsWidth="230px"
              width="335px"
              setSearchProducts={setSearchQuery}
              text={`Search by ${searchTiltle}...`}
              searchProducts={searchQuery}
              handleClear={(e) => {
                setSearchQuery('');
              }}
              dontShowIcon={true}
              filteredData={filteredDataList}
            />
          </Grid>
          {filteredDataList?.map((item, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <div
                style={{
                  minWidth: '3rem',
                  textAlign: 'center',
                  color: Colors.TOURQUISE,
                  marginRight: '.5rem',
                  padding: '0.2rem 0',
                  whiteSpace: 'nowrap'
                }}>
                {`${index + 1}.`}
              </div>
              <span>{item}</span>
            </div>
          ))}
        </>
      )}
    </Grid>
  );
}

export default ListView;
