import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Grid, Typography, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { Colors } from '../config/default';

function LoadNextAndPrevDoc({
  id,
  setCurrentId,
  drawline,
  imageDimensions,
  resetStates,
  handleSave
}) {
  const queue = useSelector((state) => state?.pending?.pending[0]);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const documents = queue?.filter(
    (doc) =>
      doc.documentStatus === 'Populated' ||
      doc.documentStatus === 'on Hold' ||
      doc.documentStatus === 'Reject' ||
      (doc.documentStatus === 'Approved' && doc.erpQbdStatus === 'Error')
  );
  const currentDocumentId = id;
  const navigate = useNavigate();
  const currentIndex = documents.findIndex((doc) => doc._id === currentDocumentId);

  const loadNextDocument = async () => {
    try {
      await handleSave();
      if (documents && documents.length > 0) {
        const nextIndex = (currentIndex + 1) % documents.length;
        const nextDocumentId = documents[nextIndex]._id;
        resetStates();
        setCurrentId(nextDocumentId);
        navigate(`/canvas/${nextDocumentId}`);
        drawline('', '', '', '', imageDimensions);
      }
    } catch (error) {}
  };

  const loadPrevDocument = async () => {
    try {
      await handleSave();
      if (documents && documents.length > 0) {
        const prevIndex = (currentIndex - 1 + documents.length) % documents.length;
        const prevDocumentId = documents[prevIndex]._id;
        resetStates();
        setCurrentId(prevDocumentId);
        navigate(`/canvas/${prevDocumentId}`);
        drawline('', '', '', '', imageDimensions);
      }
    } catch (error) {}
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      const isInputField = document.activeElement.tagName === 'INPUT';
      if (isInputField) {
        return;
      }
      if (event.key === 'ArrowRight') {
        loadNextDocument();
      } else if (event.key === 'ArrowLeft') {
        loadPrevDocument();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [documents, currentIndex, loadNextDocument, loadPrevDocument]);
  return (
    <Grid
      item
      xs={12}
      sx={{
        position: 'fixed',
        right: { xs: '56%', lg: userRole === 'Owner' ? '45%' : '58%' },
        top: { xs: '60px', lg: '23px' },
        zIndex: '100'
      }}>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5em' }}>
        <Grid
          container
          sx={{
            height: '1.5em',
            width: '1.5em',
            background: '#373735',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0.2em',
            '&:hover': {
              background: currentIndex === 0 ? '' : '#13293E'
            },
            '&:disabled': {
              background: '#13293E',
              color: 'gray !important',
              opacity: '0.6',
              cursor: 'not-allowed'
            }
          }}>
          <IconButton
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 0
            }}
            disabled={currentIndex === 0}
            onClick={() => loadPrevDocument()}>
            <ArrowBackIosNewIcon sx={{ color: Colors.WHITE, height: '.6em', width: '.6em' }} />
          </IconButton>
        </Grid>

        <Grid item>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', fontSize: '0.85em', color: Colors.WHITE }}>
            Document {currentIndex + 1} of {documents?.length}
          </Typography>
        </Grid>

        <Grid
          container
          item
          disabled={currentIndex === documents?.length - 1}
          sx={{
            height: '1.5em',
            width: '1.5em',
            background: '#373735',
            borderRadius: '0.2em',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              background: currentIndex === documents?.length - 1 ? '' : '#13293E'
            },
            '&:disabled': {
              background: '#13293E',
              color: 'gray',
              opacity: '0.6',
              cursor: 'not-allowed'
            }
          }}>
          <IconButton
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              p: 0
            }}
            disabled={currentIndex === documents?.length - 1}
            onClick={() => loadNextDocument()}>
            <ArrowForwardIosIcon
              sx={{
                color: Colors.WHITE,
                height: '.6em',
                width: '.6em'
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoadNextAndPrevDoc;
