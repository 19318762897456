import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Typography, Hidden } from '@mui/material';
import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';

import SignIn from '../components/signIn';
import SignUp from '../components/signUp';
import logo from '../../src/assets/BLogo.svg';
import LoginTransition from '../components/loginTransition';
import bgImage from '../../src/assets/Background.png';
import ForgotPassword from '../components/forgotPassword';
import { headings, userRoles } from '../constants/appConstants';

export default function Auth() {
  const navigate = useNavigate();
  const { AUTH_HEADING, BEIING_HUMAN } = headings;
  const { ACCOUNTANT, ADMIN, OWNER, VENDOR } = userRoles;
  const [forgotPassword, setForgotPassword] = useState(false);
  const [signIn, setSignIn] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isTransitionCompleted, setIsTransitionCompleted] = useState(true);

  useEffect(() => {
    const token = localStorage?.getItem('token');
    const user = localStorage?.getItem('userRole');
    if (token) {
      if (user === OWNER || user === ACCOUNTANT) {
        navigate('/home');
      } else if (user === ADMIN) {
        navigate('/admin');
      } else if (user === VENDOR) {
        navigate('/vendor');
      } else {
      }
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'primary.light',
          height: '100vh',
          backgroundImage:
            isHovered || !isTransitionCompleted || loading ? `url(${bgImage})` : 'none',
          backgroundSize: 'cover'
        }}>
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          {!isTransitionCompleted || loading ? (
            ''
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '2rem',
                flexDirection: { xs: 'column', md: 'row' }
              }}>
              <Hidden mdDown>
                <Typography variant="h1">{AUTH_HEADING}</Typography>
              </Hidden>
              <img src={logo} alt="Logo" style={{ margin: '0 .7rem', width: '5em' }} />

              <Typography variant="h1">{BEIING_HUMAN}</Typography>
            </Grid>
          )}
          {signIn && (!isTransitionCompleted || loading) ? (
            <LoginTransition setIsTransitionCompleted={setIsTransitionCompleted} />
          ) : (
            <Grid
              item
              xs={9}
              md={5}
              lg={2.5}
              className={`form-container ${
                signIn ? 'sign-in' : forgotPassword ? 'forget-password' : 'sign-up'
              }`}>
              {forgotPassword ? (
                <ForgotPassword
                  setForgotPassword={setForgotPassword}
                  setSignIn={setSignIn}
                  setIsHovered={setIsHovered}
                />
              ) : signIn && !loading ? (
                <SignIn
                  setForgotPassword={setForgotPassword}
                  setIsHovered={setIsHovered}
                  isHovered={isHovered}
                  setSignIn={setSignIn}
                  setLoading={setLoading}
                  loading={loading}
                  isTransitionCompleted={isTransitionCompleted}
                />
              ) : (
                <SignUp role="Owner" setIsHovered={setIsHovered} setSignIn={setSignIn} />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
