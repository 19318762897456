import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { useToast } from '../toast/toastContext';

import { Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import {
  uploadFiles,
  uploadPdf,
  getSelectedDocs,
  uploadCsv,
  getUserInfo
} from '../services/services';
import FileList from '../components/fileList';
import Btn from '../components/button';
import { dropzoneData } from '../constants/appConstants';
import { get_queue, user_information, get_tabs } from '../redux/actions/action';
import theme from '../theme';
import { Colors } from '../config/default';
import erpConfig from '../config/erpConfig';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: '15px',
  borderColor: Colors.DEEPGREY,
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const focusedStyle = {
  borderColor: Colors.DEEPGREY
};

const acceptStyle = {
  borderColor: Colors.TOURQUISE
};

const rejectStyle = {
  borderColor: Colors.RED
};

export default function Basic({
  files,
  name,
  setFiles,
  setOpen,
  removeFile,
  permission,
  setPermission,
  icon,
  uploadDocScreen,
  setSplitDoc,
  setUploadPdfScreen,
  setSplitDocDaata,
  accept,
  getInformation,
  disable,
  setAnchorEl,
  multiple
}) {
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };
  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      multiple: multiple ? true : false,
      accept:
        accept === 'pdf'
          ? {
              'application/pdf': ['.pdf']
            }
          : accept === 'csv'
          ? {
              'text/csv': ['.csv']
            }
          : ''
    });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToast();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const erpType = userDetails?.user?.erpType;
  const email = useSelector((state) => state.signIn?.signIn?.cognitoRes?.idToken?.payload?.email);
  const cognitoUsername = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.accessToken?.payload?.username
  );
  const savedFilters = useSelector((state) => state?.filters?.filters);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const type =
    permission === 'Quote'
      ? 'Estimate'
      : permission === 'Delivery Ticket'
      ? 'DeliveryTicket'
      : permission;
  const { BTN_TEXT, UPLOADING_INFO, SUCCESS, PDF_UPLOADING_INFO } = dropzoneData;
  const Tabs = {
    ...globalActiveTab,
    documentChildActiveTab: 'To Review'
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const documentRef = 'null';
    const result = await uploadFiles(files, type, documentRef);
    if (result?.data?.metadata?.status === SUCCESS) {
      showToast(result?.data?.metadata?.message, 'success');
      setOpen(false);
      setFiles([]);
      setPermission('Invoice');
      const response = await getSelectedDocs('', savedFilters, callSource);
      dispatch(get_tabs(Tabs));
      if (response?.response?.status === 401) {
        localStorage.clear();
        navigate('/');
      }
      dispatch(get_queue(response?.data?.payload?.data));
    } else if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
      setFiles([]);
      setPermission('Invoice');
    }
    setLoading(false);
  };
  const handlePdfUpload = async () => {
    setLoading(true);
    const result = await uploadPdf(files);
    if (result?.data?.metadata?.status === SUCCESS) {
      showToast(result?.data?.metadata?.message, 'success');
      setSplitDocDaata(result?.data?.payload?.data);
      setUploadPdfScreen(false);
      setSplitDoc(true);
      setFiles([]);
    } else if (result?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
      setFiles([]);
    }
    setLoading(false);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  function formatDataForRentalManPo(parsedData) {
    const ordersMap = new Map();

    parsedData?.forEach((item, index) => {
      const orderNumber = item['Order Number']?.trim();

      if (!ordersMap.has(orderNumber)) {
        ordersMap.set(orderNumber, {
          name: item['Vendor Name']?.trim(),
          vendor: item['Vendor Code']?.trim(),
          date: item['Order Date']?.trim(),
          id: orderNumber,
          amount: item['Amount Incl Tax']?.trim(),
          ['Ipgl#']: item['Ipgl#']?.trim(),
          lineItems: []
        });
      }

      const lineItem = {
        qty: item['Quantity Received']?.trim(),
        description: item['Item Description']?.trim(),
        unitPrice: item['Item Unit Price']?.trim(),
        amount: item['Amount']?.trim(),
        id: item['Line Number']?.trim()
      };

      ordersMap?.get(orderNumber)?.lineItems?.push(lineItem);
    });

    return Array?.from(ordersMap?.values());
  }

  const csvProcessing = erpConfig.csvProcessing;
  const handleAccount = async (val) => {
    setLoading(true);
    Papa?.parse(files[0] ?? '', {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const parsedData = results?.data;
        let accountValues;
        const transformedData = [];
        let currentEntry = null;

        if (val === 'accounts') {
          accountValues = csvProcessing?.GLAccounts[erpType](parsedData);
        } else if (val === 'vendors') {
          accountValues = csvProcessing?.VendorList[erpType](parsedData);
        } else if (val === 'costType') {
          accountValues = csvProcessing?.CostTypeList(parsedData);
        } else if (val === 'jobIds') {
          accountValues = csvProcessing.JobList[erpType](parsedData);
        } else if (val === 'phase') {
          accountValues = csvProcessing.Phases(parsedData);
        } else if (val === 'costIds') {
          accountValues = csvProcessing.CostList(parsedData);
        } else if (val === 'purchaseOrder' && erpType === 'RentalMan') {
          const transformedData = formatDataForRentalManPo(parsedData);
          accountValues = transformedData;
        } else if (val === 'purchaseOrder') {
          for (const item of parsedData) {
            if (item?.name && item?.vendor && item?.date && item?.id && item.amount) {
              if (currentEntry) {
                transformedData?.push(currentEntry);
              }
              currentEntry = {
                name: item.name,
                vendor: item.vendor,
                date: item.date,
                id: item.id,
                amount: item.amount,
                lineItems: []
              };
            }
            if (
              item[''] !== undefined &&
              item.lineItems !== undefined &&
              item._1 !== undefined &&
              item._2 !== undefined
            ) {
              if (currentEntry) {
                currentEntry?.lineItems?.push({
                  qty: item.lineItems,
                  description: item[''],
                  unitPrice: item._1,
                  amount: item._2
                });
              }
            }
          }
          if (currentEntry) {
            transformedData?.push(currentEntry);
          }
        }
        const params = {
          email: email,
          glInfo: val,
          dataArray:
            val === 'purchaseOrder' && erpType !== 'RentalMan' ? transformedData : accountValues,
          newGlList: disable ? (permission === 'Yes' ? true : false) : true,
          erpType: erpType
        };

        const result = await uploadCsv(params);
        if (result?.data?.metadata?.status === SUCCESS) {
          showToast(result?.data?.metadata?.message, 'success');
          const getUserresult = await getUserInfo(cognitoUsername);
          if (getUserresult?.status === 200) {
            dispatch(user_information(getUserresult?.data?.payload?.data));
            setOpen(false);
            setFiles([]);
            setPermission('');
            setLoading(false);
            getInformation();
            setAnchorEl(null);
          }
        } else if (result?.response?.status === 401) {
          localStorage.clear();
          navigate('/');
        } else {
          showToast(result?.response?.data?.metadata?.message, 'error');
          setLoading(false);
        }
      }
    });
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        item
        sx={{
          padding: '.5rem',
          justifyContent: 'center',
          marginBottom: '1rem'
        }}>
        <Grid item xs={11} sx={{ padding: '1rem' }}>
          <section className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} type="file" accept="application/pdf" />
              <Grid
                item
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: uploadDocScreen ? '' : '12em !important'
                }}>
                {icon}
                {uploadDocScreen ? (
                  <p style={{ textAlign: 'center' }}>{name ? name : UPLOADING_INFO}</p>
                ) : (
                  <>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: Colors.WHITE,
                        fontSize: '1em',
                        marginTop: '1em'
                      }}>
                      {PDF_UPLOADING_INFO}
                    </Typography>
                  </>
                )}
              </Grid>
            </div>
            <aside>
              <Grid item xs={12} sx={{ marginTop: '.5rem' }}>
                <FileList
                  files={files}
                  removeFile={removeFile}
                  setFiles={setFiles}
                  loading={loading}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '.5rem'
                }}>
                {uploadDocScreen === true ? (
                  <Btn
                    disabled={loading || files?.length === 0 || permission === ''}
                    text={BTN_TEXT}
                    loading={loading}
                    variant="contained"
                    width="7rem"
                    radius="10px"
                    height="2.5rem"
                    onClick={handleClick}
                    color={Colors.WHITE}
                    backgroundColor={Colors.DEEPBLUE}
                  />
                ) : uploadDocScreen === false ? (
                  <Btn
                    disabled={loading || files?.length === 0}
                    text="Upload"
                    loading={loading}
                    variant="contained"
                    width="7rem"
                    radius="10px"
                    height="2.5rem"
                    onClick={handlePdfUpload}
                    color={Colors.WHITE}
                    backgroundColor={Colors.DEEPBLUE}
                  />
                ) : (
                  <Btn
                    disabled={loading || files?.length === 0 || (disable && permission === '')}
                    text="Upload"
                    loading={loading}
                    variant="contained"
                    width="7rem"
                    radius="10px"
                    height="2.5rem"
                    onClick={() => handleAccount(uploadDocScreen)}
                    color={Colors.WHITE}
                    backgroundColor={Colors.DEEPBLUE}
                  />
                )}
              </Grid>
            </aside>
          </section>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
