import * as React from 'react';

import { Box, Modal, Button, Grid, CircularProgress } from '@mui/material';

import { Colors } from '../config/default';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '75%', sm: 400 },
  bgcolor: Colors.NAV_BLACK,
  borderRadius: '1em',
  padding: 3
};

export default function Prompt({
  title,
  description,
  leftButton,
  rightButton,
  open,
  setOpen,
  handleClick,
  loading,
  params,
  opacity,
  deleteContent,
  pullBackContent
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: { backgroundColor: opacity ? `rgba(0, 0, 0,  0.05)` : '' }
        }}>
        <Box sx={style}>
          <Grid
            sx={{
              color: Colors.WHITE,
              fontWeight: '500',
              fontSize: '1.15em',
              textAlign: 'center'
            }}>
            {title}
          </Grid>
          <Grid
            sx={{ textAlign: 'center', color: Colors.LIGHTGREY, mt: '0.75em', fontSize: '1em' }}>
            {description}
          </Grid>
          {deleteContent && (
            <Grid
              sx={{
                textAlign: 'center',
                color: Colors.LIGHTGREY,
                mt: '0.75em',
                fontSize: '0.85em',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '0em',
                  height: '0em',
                  borderRadius: '20px'
                }
              }}>
              "{deleteContent}" ?
            </Grid>
          )}
          {pullBackContent && (
            <Grid
              sx={{
                textAlign: 'center',
                color: Colors.LIGHTGREY,
                mt: '0.75em',
                fontSize: '0.85em',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '0em',
                  height: '0em',
                  borderRadius: '20px'
                }
              }}>
              "{pullBackContent}" ?
            </Grid>
          )}

          <Grid container sx={{ justifyContent: 'center', gap: '0.75em', mt: '1.5em' }}>
            <Button
              variant="outlined"
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpen(false);
              }}
              sx={{
                textTransform: 'none',
                color: Colors.LIGHTGREY,
                borderColor: Colors.LIGHTGREY,
                height: '2rem',
                width: '6.5em',
                borderRadius: '8px',
                fontSize: '1em',
                '&:hover': { backgroundColor: 'transparent', borderColor: Colors.LIGHTGREY },
                '&:disabled': {
                  color: 'gray',
                  borderColor: 'gray'
                }
              }}>
              {leftButton}
            </Button>
            <Button
              disabled={loading}
              variant="outlined"
              sx={{
                textTransform: 'none',
                color: Colors.RED,
                borderColor: Colors.RED,
                borderRadius: '8px',
                width: '6.5em',
                height: '2rem',
                fontSize: '1em',
                '&:hover': { backgroundColor: 'transparent', borderColor: Colors.RED },
                '&:disabled': {
                  color: 'gray',
                  borderColor: 'gray'
                }
              }}
              onClick={(e) => {
                handleClick(e, params && params);
              }}>
              {loading ? <CircularProgress size={18} color="inherit" /> : rightButton}
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
