import React from 'react';
import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

import { CircularProgress, Grid, Typography, Divider } from '@mui/material';
import { Colors } from '../config/default';
import { getApprovers } from '../services/services';
import { accountDetailsComponent } from '../constants/appConstants';
import {
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineItem,
  Timeline
} from '@mui/lab';
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from '@mui/lab/TimelineOppositeContent';
import { CheckCircle, ErrorOutline } from '@mui/icons-material';

export default function ApproversCard({ id }) {
  const {
    APPROVED_BY,
    PENDING_APPROVERS,
    SITTING_WITH,
    NOT_APPLIED,
    APPROVERS,
    DOCUMENT_STATUS,
    STATUS_APPROVED
  } = accountDetailsComponent;

  const [approversArray, setApproversArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectedData = async () => {
    setLoading(true);
    const result = await getApprovers(id);
    if (result?.status === 200) {
      setApproversArray(result?.data?.payload?.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    selectedData();
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          height: { xs: '75vh', xl: '48vh' },
          width: { xs: '20em', sm: '28em', xl: '42em' },
          padding: '1em 0.8em',
          borderRadius: '1em',
          backgroundColor: Colors.BG_BLACK,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          m: '1em 2em 0em 2em'
        }}>
        <Grid item>
          <Typography sx={{ color: Colors.TOURQUISE, fontSize: '1em', ml: '1.1em' }}>
            {APPROVERS}
          </Typography>
        </Grid>
        {loading ? (
          <Grid container sx={{ alignItems: 'center', justifyContent: 'center', height: '60vh' }}>
            <CircularProgress size={22} sx={{ color: Colors.DARKBLUE }} />
          </Grid>
        ) : (
          <Grid
            item
            sx={{
              height: { xs: '65vh', xl: '35vh' },
              overflowY: 'auto'
            }}>
            {!isEmpty(approversArray) &&
            (!isEmpty(approversArray?.waiting) ||
              !isEmpty(approversArray?.pending) ||
              !isEmpty(approversArray?.approved)) ? (
              <Timeline
                className="timeline-root"
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2
                  }
                }}>
                {!isEmpty(approversArray?.approved) && (
                  <TimelineItem sx={{ minHeight: '10px' }}>
                    <TimelineOppositeContent
                      sx={{
                        width: { xs: '3em', sm: '5em' }
                      }}>
                      <Typography
                        sx={{
                          color: Colors.TEXTGREY,
                          fontSize: { xs: '10px', sm: '0.7em', xl: '1em' },
                          fontWeight: '600'
                        }}>
                        {APPROVED_BY}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ backgroundColor: '#1E606A', height: '0.3em', width: '0.3em' }}
                      />
                      <TimelineConnector sx={{ bgcolor: Colors.TEXTGREY, minHeight: '1em' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ color: Colors.TEXTGREY }}>
                      {approversArray?.approved?.map((approvers, index) => (
                        <React.Fragment key={index}>
                          {approvers?.map((item, idx) => (
                            <Typography
                              key={`${index}-${idx}`}
                              sx={{ color: Colors.TEXTGREY, fontSize: { lg: '0.8em', xl: '1em' } }}>
                              {item}
                            </Typography>
                          ))}
                          {index < approversArray?.approved?.length - 1 && (
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{
                                bgcolor: Colors.TEXTGREY,
                                height: '2em',
                                width: '.1em',
                                marginY: '0.5em',
                                alignSelf: 'center'
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </TimelineContent>
                  </TimelineItem>
                )}
                {!isEmpty(approversArray?.waiting) && (
                  <TimelineItem sx={{ minHeight: '10px' }}>
                    <TimelineOppositeContent
                      sx={{
                        width: { xs: '3em', sm: '5em' }
                      }}>
                      <Typography
                        sx={{
                          color: Colors.TEXTGREY,
                          fontSize: { xs: '10px', sm: '0.7em', xl: '1em' },
                          fontWeight: '600'
                        }}>
                        {SITTING_WITH}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{ backgroundColor: Colors.TOURQUISE, height: '0.3em', width: '0.3em' }}
                      />
                      <TimelineConnector sx={{ bgcolor: Colors.TEXTGREY, minHeight: '1em' }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ color: Colors.TEXTGREY }}>
                      {approversArray?.waiting?.map((item, index) => (
                        <Typography
                          key={index}
                          sx={{ color: Colors.TEXTGREY, fontSize: { lg: '0.8em', xl: '1em' } }}>
                          {item}
                        </Typography>
                      ))}
                    </TimelineContent>
                  </TimelineItem>
                )}
                {!isEmpty(approversArray?.pending) && (
                  <TimelineItem sx={{ minHeight: '10px' }}>
                    <TimelineOppositeContent
                      sx={{
                        width: { xs: '3em', sm: '5em' }
                      }}>
                      <Typography
                        sx={{
                          color: Colors.TEXTGREY,
                          fontSize: { xs: '10px', sm: '0.7em', xl: '1em' },
                          fontWeight: '600'
                        }}>
                        {PENDING_APPROVERS}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          backgroundColor: Colors.DISABLEDGRAY,
                          height: '0.3em',
                          width: '0.3em'
                        }}
                      />
                      <TimelineConnector sx={{ bgcolor: Colors.TEXTGREY }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ color: Colors.TEXTGREY }}>
                      {approversArray?.pending?.map((approvers, index) => (
                        <React.Fragment key={index}>
                          {approvers?.map((item, idx) => (
                            <Typography
                              key={`${index}-${idx}`}
                              sx={{ color: Colors.TEXTGREY, fontSize: { lg: '0.8em', xl: '1em' } }}>
                              {item}
                            </Typography>
                          ))}
                          {index < approversArray?.pending?.length - 1 && (
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{
                                bgcolor: Colors.TEXTGREY,
                                height: '2em',
                                width: '.1em',
                                marginY: '0.5em',
                                alignSelf: 'center'
                              }}
                            />
                          )}
                        </React.Fragment>
                      ))}
                    </TimelineContent>
                  </TimelineItem>
                )}
                {isEmpty(approversArray?.waiting) &&
                  isEmpty(approversArray?.pending) &&
                  !isEmpty(approversArray?.approved) && (
                    <TimelineItem sx={{ minHeight: '10px' }}>
                      <TimelineOppositeContent
                        sx={{
                          width: { xs: '3em', sm: '5em' }
                        }}>
                        <Typography
                          sx={{
                            color: Colors.TEXTGREY,
                            fontSize: { xs: '10px', sm: '0.7em', xl: '1em' },
                            fontWeight: '600'
                          }}>
                          {DOCUMENT_STATUS}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <CheckCircle
                          sx={{
                            color: Colors.TOURQUISE,
                            marginTop: '.5em',
                            marginBottom: '.5em',
                            fontSize: '1.2em'
                          }}
                        />
                        <TimelineConnector sx={{ bgcolor: Colors.TEXTGREY, minHeight: '1em' }} />
                      </TimelineSeparator>
                      <TimelineContent sx={{ color: Colors.TEXTGREY }}>
                        <Typography
                          sx={{ color: Colors.TOURQUISE, fontSize: { lg: '0.8em', xl: '1em' } }}>
                          {STATUS_APPROVED}
                        </Typography>
                      </TimelineContent>
                    </TimelineItem>
                  )}
              </Timeline>
            ) : (
              <Grid
                container
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  color: Colors.TEXTGREY
                }}>
                <ErrorOutline
                  sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '.9em' }}
                />
                <Typography>{NOT_APPLIED}</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
