import * as React from 'react';

import { Typography, Button, Grid } from '@mui/material';

import { Colors } from '../config/default';

export default function PermissionRadio({
  permission,
  setPermission,
  question,
  options,
  disabled
}) {
  const handleChange = (val) => {
    setPermission(val);
  };
  if (disabled) {
    setPermission('');
  }
  return (
    <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      {question && <Typography sx={{ fontSize: '15px' }}>{question}</Typography>}
      <Grid item xs={12} sx={{ mt: 2 }}>
        {options?.map((item) => (
          <Button
            sx={{
              borderRadius: '10px',
              color: 'white',
              backgroundColor: permission === item ? Colors.DARKBLUE : '',
              textTransform: 'none',
              width: item === 'Delivery Ticket' ? '8.5rem' : '5rem',
              marginRight: '.5em',
              borderColor: permission === item ? Colors.DARKBLUE : Colors.DEEPGREY,
              '&:hover': {
                background: permission === item ? Colors.DARKBLUE : ''
              }
            }}
            onClick={() => handleChange(item)}
            type="submit"
            variant="outlined">
            {item}
          </Button>
        ))}
      </Grid>
    </Grid>
  );
}
