import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Hidden } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import IntegrationButton from './integrationButton';
import { Colors } from '../config/default';
import MainCards from './listCard';
import quickBooksIcon from '../assets/qbLogo.png';
import FoundationLogo from '../assets/foundationLogo.png';
import RentalManLogo from '../assets/rentalManLogo.png';
import { user_information, get_tabs } from '../redux/actions/action';
import { IntegrationPage } from '../constants/appConstants';

import {
  connectIntegration,
  saveIntegration,
  getUserInfo,
  diconnectIntegration,
  syncData
} from '../services/services';
import { useToast } from '../toast/toastContext';
import {
  formatDate,
  formatNumberWithoutDecimal,
  constructionData,
  serviceData
} from '../services/common';
import { LearnMoreLink } from '../constants/appConstants';

export default function MyIntegerations({ erpLoading, syncing, setSyncing }) {
  const {
    INTEGRATION_MESSAGE,
    RECORD,
    QUICKBOOKS_ONLINE_SUBTITLE,
    QUICKBOOKS_DESKTOP_SUBTITLE,
    FOUNDATION_SUBTITLE,
    SESSION_EXPIRE,
    SOMETHING_WENT_WRONG,
    RENTALMAN_SUBTITLE
  } = IntegrationPage;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { showToast } = useToast();
  const isConstructionCompany = useSelector(
    (state) => state?.userInfo?.userInfo?.user?.constructionCompany
  );

  const activeIntegration = useSelector((state) => state?.userInfo?.userInfo?.activeIntegration);
  const cognitoUsername = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.accessToken?.payload?.username
  );
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const serviceAndEquipmentPermission = userDetails?.company?.serviceCodeAndEquipmentNo;
  const erpType = userDetails?.user?.erpType;
  const globalActiveTab = useSelector((state) => state?.tabs?.tabs);
  const intervalCall = async () => {
    const getUserresult = await getUserInfo(cognitoUsername);
    if (getUserresult?.status === 200) {
      dispatch(user_information(getUserresult?.data?.payload?.data));
    } else if (getUserresult?.response.status === 401) {
      localStorage.clear();
      navigate('/');
    }
  };
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (activeIntegration === '' && token) {
      const interval = setInterval(() => {
        intervalCall();
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [activeIntegration]);

  const disconnectInegration = async () => {
    setLoading(true);
    const apiResponse = await diconnectIntegration();
    if (apiResponse?.data?.metadata?.responseCode === 200) {
      showToast(apiResponse?.data?.metadata?.message, 'success');
      const getUserresult = await getUserInfo(cognitoUsername);
      if (getUserresult?.status === 200) {
        dispatch(user_information(getUserresult?.data?.payload?.data));
      }
    } else if (apiResponse?.response?.status === 401) {
      showToast(SESSION_EXPIRE, 'error');
    }
    setLoading(false);
  };
  const disconnectQuickbooksDesktop = async (e) => {
    e.preventDefault();
    localStorage.setItem('erpType', 'QuickBooksDesktop');
    disconnectInegration();
  };
  const handleAuthCall = async (url) => {
    const authData = new URL(url);
    localStorage.setItem('erpType', 'QuickBooksOnline');
    const quickBooksObj = {
      requestUrl: url
    };
    callSaveIntegration(quickBooksObj);
  };

  const callSaveIntegration = async (quickBooksObj) => {
    const res = await saveIntegration(quickBooksObj);
    if (res.data.metadata.status === 'SUCCESS') {
      showToast(res?.data?.metadata?.message, 'success');
      const getUserresult = await getUserInfo(cognitoUsername);
      if (getUserresult?.status === 200) {
        dispatch(user_information(getUserresult?.data?.payload?.data));
        dispatch(
          get_tabs({
            activeTab: globalActiveTab?.activeTab,
            documentChildActiveTab: globalActiveTab?.documentChildActiveTab,
            IntegrationChildActiveTab: 'My Integration'
          })
        );
        handleSyncErp(getUserresult?.data?.payload?.data?.activeIntegration);
      }
    } else if (res?.response?.status === 401) {
      showToast(SESSION_EXPIRE, 'error');
    } else {
      showToast(res?.data?.metadata?.message, 'error');
    }
  };

  const handleSyncErp = async (actIntegration) => {
    setSyncing(true);
    localStorage.setItem('erpType', actIntegration);
    let params = {
      data: []
    };
    if (isConstructionCompany) {
      params.data = serviceAndEquipmentPermission
        ? [...constructionData, 'equipment', 'serviceCode']
        : constructionData;
    } else {
      params.data = serviceAndEquipmentPermission
        ? serviceData
        : ['vendors', 'accounts', 'purchaseOrder', 'employees'];
    }

    const syncResult = await syncData(params);
    if (syncResult?.status === 200) {
      const getUserresult = await getUserInfo(cognitoUsername);
      if (getUserresult?.status === 200) {
        dispatch(user_information(getUserresult?.data?.payload?.data));
      }
    }
    setSyncing(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem('erpType', 'QuickBooksOnline');
    const res = await connectIntegration();
    if (res?.status === 200) {
      var parameters = 'location=1,width=800,height=650';
      parameters += ',left=' + (screen.width - 800) / 2 + ',top=' + (screen.height - 650) / 2;
      var win = window.open(res.data.payload.data, 'connectPopup', parameters);
      var pollOAuth = window.setInterval(function () {
        try {
          if (win.location.href.indexOf('code') != -1) {
            window.clearInterval(pollOAuth);
            handleAuthCall(win.location.href);
            win.close();
          }
        } catch (e) {}
      }, 10);
    } else if (res?.response?.status === 401) {
      showToast(SESSION_EXPIRE, 'error');
    } else {
      showToast(SOMETHING_WENT_WRONG, 'error');
    }
  };
  const handleAgaveCall = async (url) => {
    try {
      localStorage.setItem('erpType', 'Agave');
      const quickBooksObj = {
        requestUrl: url
      };
      callSaveIntegration(quickBooksObj);
    } catch (error) {
      return error;
    }
  };
  const handleClick = async (e) => {
    e.preventDefault();
    localStorage.setItem('erpType', 'QuickBooksOnline');
    disconnectInegration();
  };
  const handleFoundationIntegration = async (e) => {
    e.preventDefault();
    localStorage.setItem('erpType', 'Agave');
    setLoading(true);
    const res = await connectIntegration();
    if (res?.status === 200) {
      window.AgaveLink.openLink({
        linkToken: res?.data?.payload?.data,
        onSuccess: (publicToken) => {
          handleAgaveCall(publicToken);
        },
        sourceSystem: 'foundation-hosted'
      });
      setLoading(false);
    } else {
      showToast(SOMETHING_WENT_WRONG, 'error');
      setLoading(false);
    }
  };
  const disconnectFoundation = async (e) => {
    e.preventDefault();
    localStorage.setItem('erpType', 'Foundation');
    disconnectInegration();
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: { xs: 'center', xl: 'flex-start' },
          height: { xs: '64vh', sm: '69vh' },
          backgroundColor: Colors.BG_BLACK,
          overflowY: { xs: 'scroll', lg: 'visible' },
          '&::-webkit-scrollbar': {
            width: '0em',
            height: '0em',
            borderRadius: '20px'
          }
        }}>
        <Grid container item xs={12}>
          {userDetails?.user?.erpType === '' ||
          (userDetails?.user?.erpType !== 'QuickBooksDesktop' &&
            userDetails?.user?.erpType !== 'QuickBooksOnline' &&
            userDetails?.user?.erpType !== 'Foundation' &&
            userDetails?.user?.erpType !== 'RentalMan') ? (
            <Grid
              container
              sx={{
                color: Colors.TEXTGREY,
                justifyContent: 'center',
                alignItems: 'center',
                height: '75vh'
              }}>
              <Box
                sx={{
                  backgroundColor: Colors.NAV_BLACK,
                  padding: '.5em',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '.8em'
                }}>
                <ErrorOutlineIcon
                  sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }}
                />
                {INTEGRATION_MESSAGE}
              </Box>
            </Grid>
          ) : (
            <>
              <Grid
                item
                xs={12}
                md={3.4}
                lg={2.4}
                xl={2}
                sx={{
                  display: { xs: 'flex', lg: 'block' },
                  justifyContent: { xs: 'center', sm: 'flex-start' },
                  ml: { sm: '1em', md: '2em', lg: '0' },
                  mt: { xs: '1em', md: '0' }
                }}>
                {userDetails?.user?.erpType === 'QuickBooksDesktop' ? (
                  <IntegrationButton
                    imageWidth={160}
                    width="13em"
                    cardIcon={quickBooksIcon}
                    cardTitle="QuickBooks Desktop"
                    toggle={false}
                    buttonName={
                      activeIntegration === 'QuickBooksDesktop'
                        ? 'DISCONNECT'
                        : 'Connect to QuickBooks'
                    }
                    activity={
                      activeIntegration === 'QuickBooksDesktop' ? Colors.RED : Colors.QB_GREEN
                    }
                    handleClick={
                      activeIntegration === 'QuickBooksDesktop' ? disconnectQuickbooksDesktop : ''
                    }
                    loading={activeIntegration === 'QuickBooksDesktop' ? loading : null}
                    subTitle={
                      <>
                        {QUICKBOOKS_ONLINE_SUBTITLE}{' '}
                        <a style={{ color: Colors.TOURQUISE }} href={LearnMoreLink} target="_blank">
                          Learn More
                        </a>
                      </>
                    }
                  />
                ) : userDetails?.user?.erpType === 'QuickBooksOnline' ? (
                  <IntegrationButton
                    imageWidth={160}
                    width="13em"
                    cardIcon={quickBooksIcon}
                    cardTitle="QuickBooks Online"
                    toggle={false}
                    buttonName={
                      activeIntegration === 'QuickBooksOnline'
                        ? 'DISCONNECT'
                        : 'Connect to QuickBooks'
                    }
                    activity={
                      activeIntegration === 'QuickBooksOnline' ? Colors.RED : Colors.QB_GREEN
                    }
                    handleClick={
                      activeIntegration === 'QuickBooksOnline' ? handleClick : handleSubmit
                    }
                    loading={activeIntegration === 'QuickBooksOnline' ? loading : null}
                    subTitle={
                      <>
                        {QUICKBOOKS_DESKTOP_SUBTITLE}{' '}
                        <a style={{ color: Colors.TOURQUISE }} href={LearnMoreLink} target="_blank">
                          Learn More
                        </a>
                      </>
                    }
                  />
                ) : userDetails?.user?.erpType === 'Foundation' ? (
                  <IntegrationButton
                    width="13em"
                    cardIcon={FoundationLogo}
                    cardTitle="Foundation Software"
                    toggle={false}
                    buttonName={activeIntegration === 'Foundation' ? 'DISCONNECT' : 'Connect'}
                    activity={activeIntegration === 'Foundation' ? Colors.RED : Colors.DARKBLUE}
                    handleClick={
                      activeIntegration === 'Foundation'
                        ? disconnectFoundation
                        : handleFoundationIntegration
                    }
                    loading={activeIntegration === 'Foundation' ? loading : loading}
                    subTitle={FOUNDATION_SUBTITLE}
                  />
                ) : userDetails?.user?.erpType === 'RentalMan' ? (
                  <IntegrationButton
                    width="13em"
                    cardIcon={RentalManLogo}
                    cardTitle="RentalMan"
                    toggle={false}
                    buttonName="Connected"
                    activity={Colors.DARKBLUE}
                    subTitle={RENTALMAN_SUBTITLE}
                    imageWidth={120}
                  />
                ) : (
                  ''
                )}
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={7.8}
                lg={9.6}
                xl={10}
                sx={{
                  gap: '0.9rem',
                  padding: { xs: '1rem', md: 'auto' },
                  justifyContent: 'flex-start',
                  backgroundColor: Colors.BG_BLACK,
                  height: 'max-content',
                  maxHeight: '75vh',
                  overflowY: 'auto'
                }}>
                {activeIntegration === '' && erpType !== 'RentalMan' ? (
                  <Hidden mdDown>
                    <Box
                      sx={{
                        color: Colors.TEXTGREY,
                        backgroundColor: Colors.NAV_BLACK,
                        padding: '.6em',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '.8em',
                        position: 'absolute',
                        top: '50%',
                        right: '50%'
                      }}>
                      <ErrorOutlineIcon
                        sx={{ color: Colors.TEXTGREY, marginRight: '.3em', fontSize: '1.3em' }}
                      />
                      {RECORD}
                    </Box>
                  </Hidden>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{ color: Colors.WHITE, float: 'right', display: 'flex' }}>
                      <div style={{ color: Colors.TOURQUISE }}>
                        Last Synced At:&nbsp;&nbsp;&nbsp;
                      </div>
                      {!syncing && !erpLoading
                        ? formatDate(userDetails?.glInfo?.dataSyncAt)
                        : 'Syncing In Progress'}
                    </Grid>
                    <MainCards
                      data={formatNumberWithoutDecimal(userDetails?.glInfo?.vendor)}
                      title="Vendors"
                      type="vendors"
                      syncing={syncing}
                      searchTiltle="Vendor"
                    />
                    <MainCards
                      data={formatNumberWithoutDecimal(userDetails?.glInfo?.glAccount)}
                      title="Gl Accounts"
                      type="accounts"
                      syncing={syncing}
                      searchTiltle="Gl Account"
                    />
                    <MainCards
                      data={formatNumberWithoutDecimal(userDetails?.glInfo?.purchaseOrder)}
                      title="Purchase Orders"
                      type="purchaseOrders"
                      syncing={syncing}
                      searchTiltle="Purchase Order"
                    />
                    <MainCards
                      data={formatNumberWithoutDecimal(userDetails?.glInfo?.subContract)}
                      title="Subcontracts"
                      type="subContracts"
                      syncing={syncing}
                      searchTiltle="Subcontract"
                    />
                    {erpType === 'Foundation' && serviceAndEquipmentPermission && (
                      <>
                        <MainCards
                          data={formatNumberWithoutDecimal(userDetails?.glInfo?.equipment)}
                          title="Equipment No's"
                          type="equipments"
                          syncing={syncing}
                          searchTiltle="Equipment No"
                        />
                        <MainCards
                          data={formatNumberWithoutDecimal(userDetails?.glInfo?.serviceCode)}
                          title="Service Codes"
                          type="serviceCodes"
                          syncing={syncing}
                          searchTiltle="Service Code"
                        />
                      </>
                    )}

                    {isConstructionCompany && (
                      <>
                        <MainCards
                          data={formatNumberWithoutDecimal(userDetails?.glInfo?.costType)}
                          title={erpType !== 'QuickBooksOnline' ? 'Cost Types' : 'Classes'}
                          type="costTypes"
                          syncing={syncing}
                          searchTiltle={erpType !== 'QuickBooksOnline' ? 'Cost Type' : 'Class'}
                        />
                        {erpType !== 'QuickBooksOnline' && (
                          <>
                            {erpType !== 'DeltekComputerEase' && (
                              <MainCards
                                data={formatNumberWithoutDecimal(userDetails?.glInfo?.costIds)}
                                title="Cost Ids"
                                type="costIds"
                                syncing={syncing}
                                searchTiltle="Cost Id"
                              />
                            )}
                            {erpType !== 'QuickBooksDesktop' && (
                              <MainCards
                                data={formatNumberWithoutDecimal(userDetails?.glInfo?.phase)}
                                title="Phases"
                                type="phaseList"
                                syncing={syncing}
                                searchTiltle="Phase"
                              />
                            )}
                          </>
                        )}
                        <MainCards
                          data={formatNumberWithoutDecimal(userDetails?.glInfo?.jobIds)}
                          title={erpType !== 'QuickBooksOnline' ? 'Job Ids' : 'Project Ids'}
                          type="jobIds"
                          syncing={syncing}
                          searchTiltle={erpType !== 'QuickBooksOnline' ? 'Job Id' : 'Project Id'}
                        />
                      </>
                    )}
                    {erpType === 'Foundation' && (
                      <MainCards
                        data={formatNumberWithoutDecimal(userDetails?.glInfo?.termsList)}
                        title="Payment Terms"
                        type="termsList"
                        syncing={syncing}
                        searchTiltle="Payment Term"
                      />
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
}
