import React, { useState, useEffect } from 'react';
import { Box, Grid, Checkbox, Typography } from '@mui/material';
import { Check, KeyboardArrowDown, KeyboardArrowUp, Error } from '@mui/icons-material';

import { replaceContentNames, validateData } from '../services/validationService';
import { Colors } from '../config/default';
import PermissionDropdown from './dropdown';
import { HtmlTooltip } from '../services/common';

function PaymentFields({
  dataArray,
  actualPaymentValues,
  buttonColors,
  prepaid,
  setPrepaid,
  setActualPaymentValues,
  annotationType,
  handleTextClick,
  paymentType,
  setPaymentType,
  setPaymentValidationErrors
}) {
  const [expand, setExpand] = useState(true);

  useEffect(() => {
    const newValidationErrors = {};
    actualPaymentValues?.forEach((value, idx) => {
      const validation = validateData(
        dataArray[idx]?.name === 'payment_type' ? paymentType : value,
        dataArray[idx]?.name,
        annotationType,
        prepaid,
        paymentType
      );
      if (!validation.status) {
        newValidationErrors[dataArray[idx]?.name] = validation.message;
      }
    });
    setPaymentValidationErrors(newValidationErrors);
  }, [actualPaymentValues, annotationType, dataArray, prepaid, paymentType]);
  const handleValueChange = (value, index) => {
    let updatedArray = [...actualPaymentValues];
    updatedArray[index] = value;
    setActualPaymentValues(updatedArray);
  };

  const iconStyle = {
    fontSize: '1.5em',
    marginRight: '.5rem'
  };
  return (
    <div>
      <p className="details-heading">Payment Details</p>
      {dataArray?.map((value, idx) => {
        const isError = validateData(
          value?.name === 'payment_type' ? paymentType : actualPaymentValues[idx],
          value?.name,
          annotationType,
          prepaid,
          paymentType
        );
        return (!prepaid &&
          (value?.name === 'payment_type' ||
            value?.name === 'check_no' ||
            value?.name === 'payment_amount' ||
            value?.name === 'payment_date')) ||
          (!expand &&
            (value?.name === 'check_no' ||
              value?.name === 'payment_amount' ||
              value?.name === 'payment_date')) ||
          (paymentType !== 'CHK' && value?.name === 'check_no') ? null : (
          <div className="payment-value-Button" key={idx}>
            <Box className="box-style" style={{ backgroundColor: Colors.NAV_BLACK }}>
              <p
                style={{
                  marginLeft: '15px',
                  color: Colors.WHITE,
                  display: 'inline-flex'
                }}>
                {value.name === 'payment_status' ? (
                  <Check
                    sx={{
                      ...iconStyle,
                      color: buttonColors[idx] === true ? Colors.DARKBLUE : ''
                    }}
                    onClick={() => handleTextClick(idx, 'success')}
                  />
                ) : value.name === 'payment_type' ? (
                  !expand ? (
                    <KeyboardArrowDown sx={iconStyle} onClick={() => setExpand(true)} />
                  ) : (
                    <KeyboardArrowUp sx={iconStyle} onClick={() => setExpand(false)} />
                  )
                ) : (
                  <div style={{ marginRight: '1.6rem' }}></div>
                )}
                {replaceContentNames(value.name)}
              </p>
            </Box>
            {/* Above are heading conditions */}
            {value.name === 'payment_status' ? (
              <div style={{ width: '52%' }}>
                <Checkbox
                  sx={{
                    color: '#1AACAC !important',
                    paddingLeft: '0px !important',
                    marginLeft: '.9rem',
                    float: 'right'
                  }}
                  checked={prepaid}
                  onChange={(e) => {
                    setPrepaid(e.target.checked);
                    // if (!e.target.checked) {
                    //   setPaymentType('');
                    // }
                  }}
                />
              </div>
            ) : value?.name === 'payment_type' ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <div className={!isError?.status ? 'dropdown-with-error' : 'first-input'}>
                  <PermissionDropdown
                    permission={paymentType}
                    setPermission={setPaymentType}
                    question=""
                    option1="Check"
                    option2="Credit Card"
                    option3="EFT"
                    color={Colors.TEXTGREY}
                    toShow="paymentMethods"
                    setExpand={setExpand}
                  />
                </div>
                {!isError?.status && (
                  <HtmlTooltip
                    title={
                      <Typography color="inherit" sx={{ fontSize: '1em' }}>
                        {isError?.message}
                      </Typography>
                    }>
                    <Error
                      sx={{
                        color: Colors.RED
                      }}
                    />
                  </HtmlTooltip>
                )}
              </div>
            ) : (
              <div className="input-wrapper">
                <input
                  className={!isError?.status ? 'input-field-style-error' : 'input-field-style'}
                  value={actualPaymentValues[idx]}
                  onChange={(e) => {
                    handleValueChange(e.target.value, idx);
                  }}
                />
                {!isError?.status && (
                  <HtmlTooltip
                    title={
                      <Typography color="inherit" sx={{ fontSize: '1em' }}>
                        {isError?.message}
                      </Typography>
                    }>
                    <Error
                      sx={{
                        color: Colors.RED
                      }}
                    />
                  </HtmlTooltip>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default PaymentFields;
