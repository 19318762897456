import React, { useEffect } from 'react';

import { Grid, MenuItem, Select, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { HtmlTooltip, BootstrapInput } from '../services/common';
import { Colors } from '../config/default';
import DropdownEmails from './dropDownEmails';
import { policyPage } from '../constants/appConstants';

export default function LevelCards({
  index,
  setData,
  item,
  allEmails,
  policyBase,
  policyData,
  vendors,
  jobIds,
  setRemainingData,
  setSelectedData
}) {
  const { MAX_VALUE_CHECK, REQUIRED_APPROVALS, AUTO_APPROVED } = policyPage;
  const approvalArray = [0, 1, 2, 3];

  const policyOptions = policyBase === 'vendor' ? vendors : jobIds;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        borderRadius: '10px'
      }
    }
  };

  const removeselectedVendor = (i) => {
    let newVendor = [];
    policyData?.levels?.forEach((level) => {
      level?.match?.forEach((vendor) => {
        newVendor = [...newVendor, vendor];
      });
    });

    const filteredEmails =
      policyOptions &&
      policyOptions?.filter((childItem) => {
        return item?.match?.includes(childItem) || !newVendor.includes(childItem);
      });
    return filteredEmails?.map((item) => item);
  };

  const removeSelectedValue = (i) => {
    let newArray = [];
    item?.approvals?.forEach((email) => {
      newArray = [...newArray, ...email];
    });

    const filteredEmails =
      allEmails &&
      allEmails?.filter((childItem) => {
        return (
          (item?.approvals?.length > i && item?.approvals[i]?.includes(childItem?.email)) ||
          !newArray.includes(childItem?.email)
        );
      });
    return filteredEmails?.map((item) => item?.email);
  };

  const handleMaxValueChange = (e) => {
    const newMaxValue = Math.max(0, parseInt(e.target.value, 10));
    setData({ ...item, max: newMaxValue });
  };
  useEffect(() => {
    const options = policyBase === 'vendor' ? vendors : jobIds;

    const allSelectedItems = policyData?.levels
      ?.flatMap((level) => level?.match || []) // Extract all `match` arrays
      ?.filter((item, index, self) => self?.indexOf(item) === index); // Remove duplicates

    const remainingItems = options?.filter((item) => !allSelectedItems?.includes(item));

    setSelectedData(allSelectedItems);
    setRemainingData(remainingItems);
  }, [policyData, policyBase, vendors, jobIds]);
  const handleRequiredApprovalsChange = (e) => {
    const newRequiredApprovals = parseInt(e.target.value, 10);
    const currentApprovals = item?.approvals || [];
    const selectedApprovals = currentApprovals.slice(0, newRequiredApprovals);

    setData({
      ...item,
      requiredApprovals: newRequiredApprovals,
      approvals: selectedApprovals
    });
  };

  return (
    <Grid
      container
      sx={{
        padding: '.7em 0em',
        mt: '1em',
        backgroundColor: 'primary.light',
        borderRadius: '0.55em',
        boxShadow: 'none',
        paddingLeft: '1.25em'
      }}>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          marginRight: '.5em'
        }}>
        <Grid item>
          <Typography
            sx={{
              color: Colors.WHITE,
              fontSize: '0.75em',
              marginBottom: '.5rem'
            }}>
            Level {index + 1}
          </Typography>
        </Grid>

        <Grid item container sx={{ gap: '0.7em', alignItems: 'center', marginRight: '1.5rem' }}>
          {policyBase === 'vendor' || policyBase === 'jobId' ? (
            <DropdownEmails
              setMails={(vendor) => setData({ ...item, match: vendor })}
              mails={item?.match}
              apiData={removeselectedVendor()}
              toShow="vendor"
              height={true}
            />
          ) : (
            <>
              <Grid item>
                <input
                  readOnly
                  style={{
                    width: '5em',
                    height: '1.2em',
                    outline: 'none',
                    border: '.7px solid #BABABA',
                    backgroundColor: 'transparent',
                    color: Colors.LIGHTBLUE,
                    borderRadius: '7px',
                    padding: '0.5em 0.2em',
                    fontSize: '0.75em',
                    textAlign: 'center',
                    fontWeight: 'bold'
                  }}
                  type="number"
                  value={item?.min}
                />
              </Grid>
              <Grid item>
                <Typography sx={{ color: Colors.TEXTGREY, fontSize: '0.8em' }}>to</Typography>
              </Grid>
              <HtmlTooltip
                title={
                  <Typography color="inherit" sx={{ fontSize: '1em' }}>
                    {MAX_VALUE_CHECK}
                  </Typography>
                }>
                <Grid item>
                  <input
                    style={{
                      width: '5em',
                      outline: 'none',
                      height: '1.2em',
                      border: '.7px solid #BABABA',
                      backgroundColor: 'transparent',
                      color: Colors.LIGHTBLUE,
                      min: '0',
                      padding: '0.5em 0.2em',
                      borderRadius: '7px',
                      fontSize: '0.75em',
                      textAlign: 'center',
                      fontWeight: 'bold'
                    }}
                    type="number"
                    value={item?.max}
                    onChange={handleMaxValueChange}
                  />
                </Grid>
              </HtmlTooltip>
            </>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginRight: '1.5rem'
        }}>
        <Grid item>
          <Typography
            sx={{
              color: Colors.WHITE,
              fontSize: '0.75em',
              marginBottom: '.5rem'
            }}>
            {REQUIRED_APPROVALS}
          </Typography>
        </Grid>
        <Select
          variant="outlined"
          sx={{
            borderRadius: '0.6em',
            outline: 'none',
            color: Colors.TEXTGREY,
            height: '2.4em',
            fontSize: '0.80em',
            padding: '1em 0em',
            textAlign: 'center',
            width: '6rem',
            backgroundColor: Colors.Canvas_BG
          }}
          MenuProps={MenuProps}
          input={<BootstrapInput />}
          value={item?.requiredApprovals}
          onChange={handleRequiredApprovalsChange}>
          {approvalArray?.map((num, i) => {
            return (
              <MenuItem
                key={i}
                sx={{
                  color: Colors.TEXTGREY,
                  borderRadius: '8px',
                  margin: '0px 8px',
                  '&:hover': {
                    borderRadius: '7px',
                    backgroundColor: Colors.BG_BLACK
                  }
                }}
                value={num}>
                {num}
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item>
        {item?.requiredApprovals > 0 && (
          <Grid container item sx={{ gap: '1em', mt: { xs: '0.5em', lg: '0' } }}>
            {Array?.from({ length: item?.requiredApprovals }, (_, i) => (
              <DropdownEmails
                key={i}
                index={i}
                setMails={(mails) => setData({ ...item, approvals: mails })}
                mails={item?.approvals}
                apiData={removeSelectedValue(i)}
                toShow="policy"
              />
            ))}
          </Grid>
        )}
        {item?.requiredApprovals === 0 && (
          <Grid
            item
            sx={{
              paddingTop: '1.5em'
            }}>
            <div
              style={{
                backgroundColor: Colors.Canvas_BG,
                display: 'flex',
                borderRadius: '7px',
                alignItems: 'center',
                gap: '0.5em',
                padding: '.4rem'
              }}>
              <Typography
                sx={{
                  color: Colors.LIGHTBLUE,
                  fontWeight: '600',
                  fontSize: '0.8em'
                }}>
                {AUTO_APPROVED}
              </Typography>
              <CheckCircleIcon sx={{ fontSize: '1.2em', color: Colors.LIGHTBLUE }} />
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
