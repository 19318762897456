import React from 'react';
import { isEmpty, debounce } from 'lodash';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Search, Close } from '@mui/icons-material';
import InputBase from '@mui/material/InputBase';

import { Colors } from '../config/default';
import { HtmlTooltip } from '../services/common';
const StyledSearch = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '10px',
  border: '1px solid #797979',
  height: '2rem',
  color: Colors.WHITE,
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    marginRight: theme.spacing(1),
    width: 'max-content'
  },
  [theme.breakpoints.up('sm')]: {
    width: 'max-content',
    marginTop: '0'
  }
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  [theme.breakpoints.up('xs')]: {
    fontSize: '0.65em'
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1em'
  },

  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '250px',
    [theme.breakpoints.up('xs')]: {
      width: '100px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '130px'
    },
    [theme.breakpoints.up('md')]: {
      width: '250px'
    }
  }
}));
function SearchBar({
  setSearchProducts,
  text,
  setCurrentPage,
  searchProducts,
  tooltipText,
  handleKeyPress,
  handleClear,
  filteredData,
  recordExist,
  width,
  dontShowIcon,
  xsWidth
}) {
  const handleClearDebounced = debounce(handleClear, 300);
  return (
    <HtmlTooltip
      title={
        tooltipText ? (
          <Typography color="inherit" sx={{ fontSize: '1em' }}>
            {tooltipText}
          </Typography>
        ) : null
      }>
      <StyledSearch>
        <StyledInputBase
          sx={{ width: { xs: xsWidth || 'auto', lg: width ? width : 'auto' } }}
          placeholder={text}
          value={searchProducts}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => {
            setSearchProducts(e.target.value);
            setCurrentPage ? setCurrentPage(1) : '';
          }}
          onKeyPress={(e) =>
            handleKeyPress && e?.key === 'Enter' && searchProducts !== '' ? handleKeyPress() : ''
          }
        />
        {!dontShowIcon && (
          <>
            {!isEmpty(filteredData) || recordExist ? (
              <Close
                sx={{ color: Colors.DEEPGREY, marginRight: '.5em', cursor: 'pointer' }}
                onClick={() => handleClearDebounced()}
              />
            ) : (
              <Search
                sx={{ color: Colors.DEEPGREY, marginRight: '.5em', cursor: 'pointer' }}
                onClick={() => {
                  searchProducts !== '' ? handleKeyPress() : '';
                }}
              />
            )}
          </>
        )}
      </StyledSearch>
    </HtmlTooltip>
  );
}

export default SearchBar;
