import { Route, Routes } from 'react-router-dom';
import React from 'react';

import './toaster.css';
import Auth from './pages/auth';
import AdminHomePage from './pages/adminHomePage';
import CanvasLayer1 from '../src/pages/canvas';
import Protected from './components/protected';
import Registeration from './pages/registeration';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserProfile from './pages/userProfile';
import CheckoutFormPage from './pages/checkoutForm';
import HomePage from './pages/homePage';
import VerifiedDetails from './pages/verfiedDetails';
import Users from './pages/users';
import ThankyouPage from './pages/thankyou';
import VendorHomePage from './vendorPannel/pages/vendorHomePage';
import GeneratePO from './pages/generatePO';
import UnauthorizedPage from './pages/unauthorisedPage';
import ReactGA from 'react-ga4';
import { injectContentsquareScript } from '@contentsquare/tag-sdk';
import { gaTrackingId } from './constants/appConstants';
import { hotJarSiteId } from './constants/appConstants';
import LogRocket from 'logrocket';

ReactGA.initialize(gaTrackingId);

const getUserRole = () => {
  return localStorage.getItem('userRole') || 'guest';
};

injectContentsquareScript({
  siteId: hotJarSiteId,
  async: true,
  defer: false
});

const getUserToken = () => {
  return localStorage.getItem('token');
};

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  const userRole = getUserRole();
  const userToken = getUserToken();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const userName = userDetails?.user?.userName;
  const userEmail = userDetails?.user?.email;
  const userId = userDetails?.user?._id;

  if (userToken && window.location.hostname !== 'localhost' && !window.location.hostname.includes('dev')) {
    LogRocket.init('oycbjt/beiing-human');
  
    LogRocket.identify(userId, {
      name: userName,
      email: userEmail
    });
  }

  return (
    <>
      <Routes>
        <Route
          path="/admin"
          element={
            <Protected role={userRole}>
              <AdminHomePage />
            </Protected>
          }
        />
        <Route
          path="/vendor"
          element={
            <Protected role={userRole}>
              <VendorHomePage />
            </Protected>
          }
        />

        <Route
          path="/canvas/:id"
          element={
            <Protected role={userRole}>
              <CanvasLayer1 />
            </Protected>
          }
        />
        <Route
          path="/users"
          element={
            <Protected role={userRole}>
              <Users />
            </Protected>
          }
        />
        <Route
          path="/userProfile"
          element={
            <Protected role={userRole}>
              <UserProfile />
            </Protected>
          }
        />
        <Route
          path="/details/:id"
          element={
            <Protected role={userRole}>
              <VerifiedDetails />
            </Protected>
          }
        />

        <Route
          path="/generatePo/:id"
          element={
            <Protected role={userRole}>
              <GeneratePO />
            </Protected>
          }
        />

        <Route
          path="/home"
          element={
            <Protected role={userRole}>
              <HomePage />
            </Protected>
          }
        />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />
        <Route path="/paymentSuccessful" element={<ThankyouPage />} />
        <Route path="/stripe" element={<CheckoutFormPage />} />
        <Route path="/" element={<Auth />} />
        <Route path="*" element={<Navigate to="/unauthorized" />} />
        <Route path="/:role" element={<Registeration />} />
      </Routes>
    </>
  );
}

export default App;
